import styled, { css } from "styled-components"

export const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
`

export const BackgroundFilter = styled.div`
  position: absolute;
  opacity: 0.6;
  inset: 0;
  z-index: 100;
  background: linear-gradient(26deg, #2b0934 22.36%, #3d0f49 74.61%);
  backdrop-filter: brightness(0.2);
`

export const ExpertiseItemTitle = styled.h3`
  position: relative;
  min-height: 7.5rem;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  transition: 0.3s;
`

export const ExpertiseItemDescriptionContainer = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 100;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ExpertiseItemDescription = styled.p`
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5rem;
  margin: 0;
`

export const ButtonWrapper = styled.div`
  width: 12.125rem;
`

export const ExpertiseItemContainer = styled.div`
  width: 15.5rem;
  height: 17.71rem;
  border: 1px solid #563b5d;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.65rem 1.375rem;
  overflow: hidden;

  &:hover ${ExpertiseItemDescriptionContainer} {
    height: 23.875rem;
  }

  &:hover ${ExpertiseItemTitle} {
    opacity: 0;
    margin-top: -50%;
  }

  &:hover ${BackgroundFilter} {
    opacity: 0.9;
  }
`
