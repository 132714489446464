import React from "react"
import {
  ExpertiseContainer,
  ExpertisePlugItem,
  StyledArrowDownContainer,
} from "./ExpertiseSectionWrapper.style"
import { useMemo } from "react"
import ExpertiseItem from "./ExpertiseItem/ExpertiseItem"
import loadable from "@loadable/component"
import useWindowDimensions from "@hooks/useWindowDimensions"
import { EXPERTISES } from "../../constants"
import { ReactSVG } from "react-svg"
import arrowDown from "@images/home-page/chevronIconWide.svg"

const DropDownListSection = loadable(() =>
  import("@common/CommonSections/DropDownListSection/DropDownListSection")
)

const ExpertiseSectionWrapper = () => {
  const { isDesktop } = useWindowDimensions()

  const items = useMemo(() => {
    return EXPERTISES.map((data, index) => (
      <ExpertiseItem
        title={data.title}
        description={data.description}
        backgroundImage={data.backgroundImage}
        link={data.path}
        lightenImage={data.lightenImage}
        key={data.path + index}
      />
    ))
  }, [])

  return (
    <ExpertiseContainer>
      {isDesktop ? (
        <>
          {items}
          <ExpertisePlugItem>
            <StyledArrowDownContainer>
              <ReactSVG src={arrowDown} />
            </StyledArrowDownContainer>
          </ExpertisePlugItem>
        </>
      ) : (
        <DropDownListSection
          sectionItems={EXPERTISES}
          heading="Our expertise"
        />
      )}
    </ExpertiseContainer>
  )
}

export default ExpertiseSectionWrapper
