import styled from "styled-components"
import { TABLET_MAX_WIDTH } from "@constants"

export const ExpertiseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1.125rem;
  row-gap: 1.313rem;
  padding: 0 10.1875rem;

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    padding: 3rem 1.625rem 0;

    & svg {
      max-width: 2.688rem;
    }
  }
`

export const ExpertisePlugItem = styled.div`
  width: 15.5rem;
  height: 17.71rem;
  border: 1px solid #563b5d;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.65rem 1.375rem;
`

export const StyledArrowDownContainer = styled.div`
  height: 5.188rem;
  width: 5.188rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #563b5d;

  & div {
    display: flex;
  }

  & svg {
    & path {
      stroke: #563b5d;
    }
  }
`
